var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseInputSelect',_vm._g({staticClass:"meeting-member-selector redesign",class:{
    'meeting-member-selector-empty': !_vm.selected || _vm.selected.length === 0
  },attrs:{"option-value":"jid","options":_vm.members,"use-input":!_vm.single || !_vm.selected,"multiple":!_vm.single,"hide-dropdown-icon":!_vm.single,"disable":_vm.disable,"placeholder":_vm.caption,"menu-anchor":"bottom left","popup-content-class":'td-rounded-8 ' + _vm.popupContentClass,"virtual-scroll-slice-size":_vm.pageSize},on:{"virtual-scroll":_vm.onScroll,"filter":_vm.filterFn},scopedSlots:_vm._u([{key:"selected-item",fn:function(ref){
  var tabindex = ref.tabindex;
  var index = ref.index;
  var opt = ref.opt;
  var removeAtIndex = ref.removeAtIndex;
return [_c('q-chip',{staticClass:"meeting-member-selector-selected-item row q-pa-none",attrs:{"removable":!_vm.disable,"dense":"","size":"12px","tabindex":tabindex},on:{"remove":function($event){return removeAtIndex(index)}}},[_c('q-item',{staticClass:"flex items-center q-pa-none meeting-member-selector-selected-item__chip redesign"},[_c('BaseAvatar',{staticClass:"q-mr-sm q-ml-sm",attrs:{"size":"20px","icons":opt.icons}}),_c('q-item-label',{staticClass:"meeting-member-selector-selected-item__name",attrs:{"title":opt.displayName,"lines":"1"}},[_vm._v(" "+_vm._s(opt.displayName)+" ")])],1)],1)]}},{key:"option",fn:function(ref){
  var itemProps = ref.itemProps;
  var opt = ref.opt;
  var selected = ref.selected;
  var toggleOption = ref.toggleOption;
  var itemEvents = ref.itemEvents;
  var focused = ref.focused;
return [_c('q-item',_vm._g(_vm._b({staticClass:"meeting-member-selector-dropdown-item\n            flex items-center justify-center redesign q-pa-none",class:{ 'meeting-member-selector-dropdown-item-focused': focused },attrs:{"clickable":""}},'q-item',itemProps,false),itemEvents),[_c('q-item-section',{staticClass:"q-pr-sm",attrs:{"side":""}},[_c('BaseAvatar',{attrs:{"size":"36px","icons":opt.icons}})],1),_c('q-item-section',[_c('q-item-label',{staticClass:"meeting-member-selector-dropdown-item__name",attrs:{"lines":"1"}},[_vm._v(" "+_vm._s(opt.displayName)+" ")]),_c('q-item-label',{staticClass:"meeting-member-selector-dropdown-item__role",attrs:{"lines":"1"}},[_vm._v(" "+_vm._s(opt.role)+" ")])],1),(!_vm.single)?_c('q-item-section',{attrs:{"side":""}},[_c('q-checkbox',{staticClass:"meeting-member-selector-dropdown-item__checkbox",attrs:{"value":selected,"size":"24px","color":"base-brand"},on:{"input":function($event){return toggleOption(opt)}}})],1):_vm._e()],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }