































































































import BaseAvatar from '@/components/UI/BaseAvatar.vue'
import BaseInputSelect from '@/components/UI/BaseInputSelect.vue'
import { teamsStore, contactsStore } from '@/store'
import { getDefaultFuseOptions } from '@/utils'
import { multiSearch } from '@/utils/Common'
import { Contact } from '@tada-team/tdproto-ts'
import Fuse from 'fuse.js'
import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import { contacts } from 'td-api'
import { QSelect } from 'quasar'

@Component({
  components: {
    BaseAvatar,
    BaseInputSelect,
  },
})
export default class MembersSelector extends Vue {
  @VModel({
    type: [Array, Object],
  }) private readonly selected!: Contact[] | Contact

  @Prop({
    type: Boolean,
  }) private readonly disable!: boolean

  @Prop({
    type: Boolean,
  }) private readonly single!: boolean

  @Prop({
    type: String,
  }) private readonly emptyPlaceholder!: string

  @Prop({
    type: String,
  }) private readonly placeholder!: string

  @Prop({
    type: String,
  }) private readonly popupContentClass!: string

  private readonly fuseOptions = getDefaultFuseOptions(['displayName'])

  private contacts: Contact[] = []

  // paginated options
  private loading = true
  private lastPage = 1
  private pageSize = 20
  private nextPage = 1
  private offset = 0

  private filterString = ''

  private async created () {
    await this.loadContacts(this.selected instanceof Array ? this.selected : undefined)
    this.loading = false
  }

  private get members (): Contact[] {
    const query = this.filterString.trim()
    return !query.length
      ? this.contacts
      : multiSearch((s: string) => this.fuse.search(s), query, 'jid')
  }

  private async filterFn (value: string, update: (fn : () => void) => void): Promise<void> {
    update(async () => {
      if (!value && !this.filterString) return
      this.filterString = value
      this.offset = 0
      this.nextPage = 2
      this.contacts = []
      await this.loadContacts()
    })
  }

  private get fuse (): Fuse<Contact> {
    return new Fuse(this.contacts, this.fuseOptions)
  }

  private get caption () {
    if (this.disable) return ''
    let isEmpty = true
    if (this.selected instanceof Contact && this.selected) isEmpty = false
    if (this.selected instanceof Array && this.selected.length > 0) isEmpty = false
    return !isEmpty
      ? this.placeholder
      : this.emptyPlaceholder
  }

  private async onScroll (details: {
    to: number,
    index: number,
    direction: 'increase' | 'decrease',
    ref: QSelect
  }): Promise<void> {
    const { to, index, direction, ref } = details
    if (
      !this.loading &&
      (to === this.contacts.length - 1 || index === this.pageSize) &&
      direction === 'increase' &&
      !this.filterString &&
      this.nextPage <= this.lastPage
    ) {
      this.offset += this.pageSize
      this.nextPage += 1
      await this.loadContacts()
      this.$nextTick(() => {
        ref.refresh(index)
      })
    }
  }

  get me () {
    return teamsStore.getters.currentTeam.me
  }

  private async loadContacts (selectedContacts?: Contact[]): Promise<void> {
    this.loading = true
    const paginatedContacts = await contacts.getPaginatedContacts(teamsStore.getters.currentTeam.uid, {
      limit: this.pageSize,
      offset: this.offset,
      display_name: this.filterString,
      is_archived: false,
      is_bot: false,
      without_me: true,
    })
    contactsStore.mutations.addContacts(paginatedContacts.objects)
    if (selectedContacts) {
      this.contacts.push(...selectedContacts)
    }
    this.contacts.push(...paginatedContacts.objects)
    this.lastPage = Math.ceil(paginatedContacts.count / this.pageSize)
    this.loading = false
  }
}
