import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2db86357&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=2db86357&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2db86357",
  null
  
)

export default component.exports
import {QChip,QItem,QItemLabel,QItemSection,QCheckbox,QField} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QChip,QItem,QItemLabel,QItemSection,QCheckbox,QField})
